

























































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import clientStoreModule from "@/store/modules/client";
import { Business, Role } from "@/types";

const { mapActions: clientActions, mapGetters: clientGetters } =
  createNamespacedHelpers("NEW_CLIENT_LIST");

export default Vue.extend<any, any, any, any>({
  components: {},
  name: "NewClientList",
  props: {
    startDate: {
      type: String,
      default: null,
    },
    endDate: {
      type: String,
      default: null,
    },

    all: {
      type: Boolean,
      default: false,
    },
    returningClients: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    client: undefined,
    editClientDialog: false,
    headers: [
      {
        text: "Date",
        align: "start",
        sortable: true,
        value: "createdAt",
      },
      { text: "Full Name", value: "fullName" },
      { text: "Email", value: "email" },
      { text: "Gender", value: "gender" },
      { text: "Orders", value: "totalOrders" },
      { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
  }),
  computed: {
    ...clientGetters(["newClients"]),
    newClients() {
      return this.$store.state.NEW_CLIENT_LIST.newClients;
    },
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...clientActions(["fetchNewClients"]),
    viewClient(id: number) {
      this.$router.push(`/client/${id}`);
    },
    fetchNew() {
      if (this.role) {
        const businessId = (this.role.business as Business)?._id;
        const params: any = { businessId };
        if (this.startDate) params.startDate = this.startDate;
        if (this.endDate) params.endDate = this.endDate;
        if (this.all) params.fetchAll = "true";
        if (this.returningClients) params.fetchReturning = "true";
        params.limit = this.options.itemsPerPage;
        params.page = this.options.page;
        try {
          this.fetchNewClients(params);
        } catch (error) {
          console.error("Error fetching new clients:", error);
        }
      }
    },
  },
  watch: {
    startDate: "fetchNew",
    endDate: "fetchNew",
    options: {
      handler() {
        this.fetchNew();
      },
      deep: true,
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("NEW_CLIENT_LIST")) {
      this.$store.registerModule("NEW_CLIENT_LIST", clientStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("NEW_CLIENT_LIST");
  },
  mounted() {
    this.fetchNew();
  },
});
