





























import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import clientStoreModule from "@/store/modules/client";
import { Business, Role } from "@/types";

const { mapActions: clientActions, mapGetters: clientGetters } =
  createNamespacedHelpers("TOP_SERVICES_LIST");

export default Vue.extend<any, any, any, any>({
  components: {},
  name: "TopServicesList",
  props: {
    startDate: {
      default: null,
    },
    endDate: {
      default: null,
    },
  },
  data: () => ({
    client: undefined,
    showClientDialog: false,
    showDeleteDialog: false,
    selectedGender: "All",
    selectedTime: "Most Recent",
    startDateMenu: false,
    endDateMenu: false,

    options: {} as { page: number },
    editClientDialog: false,
    exporting: false,
  }),
  watch: {
    role: "fetchTopService",
    options: {
      handler() {
        this.fetchTopService();
      },
      deep: true,
    },
    startDate() {
      this.fetchTopService();
    },
    endDate() {
      this.fetchTopService();
    },
  },
  computed: {
    ...clientGetters(["topServices"]),
    role(): Role {
      return this.$store.getters.role;
    },
    headers() {
      const queryString = window.location.search;
      const ps = new URLSearchParams(queryString);
      const type = ps.get("type") || "amount";

      const heading = [
        {
          text: "Service",
          align: "start",
          sortable: true,
          value: "title",
        },
        {
          text: type === "amount" ? "Amount" : "Quantity",
          align: "start",
          sortable: true,
          value: type,
        },
      ];
      return heading;
    },
  },
  methods: {
    ...clientActions(["fetchTopServices"]),
    fetchTopService() {
      if (this.role) {
        const businessId = (this.role.business as Business)?._id;

        if (businessId) {
          const params: any = { businessId };
          if (this.startDate) params.startDate = this.startDate;
          if (this.endDate) params.endDate = this.endDate;

          const queryString = window.location.search;
          const ps = new URLSearchParams(queryString);
          params.type = ps.get("type") || "amount";
          try {
            this.fetchTopServices(params);
          } catch (error) {
            console.error("Error fetching top services:", error);
          }
        }
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("TOP_SERVICES_LIST")) {
      this.$store.registerModule("TOP_SERVICES_LIST", clientStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("TOP_SERVICES_LIST");
  },
  mounted() {
    this.fetchTopService();
  },
});
