

























































































































































































import Vue from "vue";

import OrderModule from "@/store/modules/order";
import ClientModule from "@/store/modules/client";
import { createNamespacedHelpers } from "vuex";
import { Business, Client, Role } from "@/types";
import TopFilter from "../reports/TopFilter.vue";

// import ReportDateFilter from "../reports/ReportDateFilter.vue";

const { mapGetters: orderGetters, mapActions: orderActions } =
  createNamespacedHelpers("ORDER_STORE");
const { mapGetters: orderClientsGetters, mapActions: orderClientActions } =
  createNamespacedHelpers("ORDER_CLIENTS");

export default Vue.extend<any, any, any, any>({
  name: "OrderList",
  components: {
    TopFilter,
  },
  props: {
    orderType: {
      type: String,
      default: "product",
    },
  },
  data: () => ({
    rs: ["Reports", "Sales"],
    options: {} as { page: number; itemsPerPage: number },
    isLoading: false,
    filters: {
      search: "",
      status: "all",
      email: "",
    },
    params: {},
  }),
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
    ...orderGetters(["orderPage"]),
    ...orderClientsGetters(["clientPage"]),
    customers: function (): Client[] {
      return this.clientPage.docs;
    },
    filteredData: function (): any[] {
      const { search, status, email } = this.filters;
      const results = this.orderPage.docs
        .filter((order: any) => {
          if (email) {
            return order.client?.email.match(new RegExp(email, "i"));
          } else {
            return true;
          }
        })
        .filter((order: any) => {
          if (search) {
            return order.orderNo.match(new RegExp(search, "i"));
          } else {
            return true;
          }
        })
        .filter((order: any) => {
          if (status == "all") {
            return true;
          }

          if (status == "pending") {
            return order.status == "pending";
          }

          if (status == "delivered") {
            return order.status == "delivered";
          }

          if (status == "cancelled") {
            return order.status == "cancelled";
          }
        });
      return results;
    },
    headers: function () {
      const text = this.camelCase(`${this.params.type} Amount`);
      return [
        { text: "Order ID", value: "orderID" },
        { text: "Customer", value: "client" },
        { text: "Order Date", value: "orderDate" },
        { text: "Payment", value: "payment_status" },
        { text: "Paid Amount", value: "payment" },
        { text: "Order Total", value: "total" },
        {
          text,
          value: "filterValue",
        },
        { text: "actions", value: "actions" },
      ];
    },
  },
  watch: {
    role() {
      this.loadOrders();
    },
    options: {
      deep: true,
      handler() {
        this.loadOrders();
      },
    },
  },
  mounted() {
    this.getParams();
  },
  created() {
    this.loadOrders();
  },
  methods: {
    ...orderActions(["fetchOrderList", "dropOrder"]),
    ...orderClientActions(["fetchClientList"]),
    customFilter(item: any, queryText: string) {
      const email = item.email.toLowerCase();
      const searchText = queryText.toLowerCase();
      return email.indexOf(searchText) > -1;
    },
    camelCase(str) {
      // Using replace method with regEx
      return str.replace(/\b\w/g, (x) => x.toUpperCase());
    },
    calculateCategoryTotal(order, type) {
      // Input validation
      if (!order) {
        throw new Error("Order and type parameters are required");
      }

      type = this.params.type;

      if (type.includes("deposit")) {
        type = "service";
      }

      if (!type || type == "all sale") {
        return order.payment.amount;
      }

      const validTypes = [
        "service",
        "product",
        "service package",
        "voucher",
        "membership",
        "total deposit",
      ];
      if (!validTypes.includes(type)) {
        throw new Error(
          `Invalid type. Must be one of: ${validTypes.join(", ")}`
        );
      }

      // Get the appropriate array based on type
      const categoryMap = {
        service: "services",
        product: "products",
        "service package": "servicePackages",
        voucher: "vouchers",
        membership: "memberships",
      };

      const items = order[categoryMap[type]] || [];
      console.log("Items", categoryMap[type]);

      // Calculate base total by summing the total property of each item
      const baseTotal = items.reduce(
        (sum, item) => {
          if (
            type == "product" ||
            type == "service" ||
            type == "service package"
          ) {
            return sum + item.total;
          }
          if (type == "voucher") {
            return sum + item.voucher.retailPrice;
          }
          if (type == "membership") {
            return sum + item.price.value;
          }

          if (type == "membership") {
            return sum + item.price.value;
          }
        },

        0
      );
      console.log("base total", baseTotal);

      // If no items or no discounts, return base total
      if (
        baseTotal === 0 ||
        !order.appliedDiscounts ||
        order.appliedDiscounts.length === 0
      ) {
        return baseTotal;
      }

      // Calculate discounts
      let totalDiscount = 0;

      order.appliedDiscounts.forEach((discount) => {
        // Check if discount applies to this category
        const appliedFieldMap = {
          service: { all: "allServices", applied: "servicesApplied" },
          product: { all: "allProducts", applied: "productsApplied" },
          servicePackage: {
            all: "allServicePackages",
            applied: "servicePackagesApplied",
          },
          voucher: { all: "allMemberships", applied: "membershipsApplied" },
          membership: { all: "allMemberships", applied: "membershipsApplied" },
        };

        const { all: allField, applied: appliedField } = appliedFieldMap[type];

        // If allField is true, apply discount, otherwise check specific items
        const isApplicable =
          discount[allField] ||
          (!discount[allField] &&
            discount[appliedField] &&
            discount[appliedField].some((id) =>
              items.some(
                (item) =>
                  item.service?._id === id ||
                  item._id === id ||
                  item.membership?._id === id
              )
            ));

        if (isApplicable) {
          if (discount.valueType === "fixed-amount") {
            totalDiscount += discount.value || 0;
          } else if (discount.valueType === "percentage") {
            totalDiscount += baseTotal * ((discount.value || 0) / 100);
          }
        }
      });

      // Return total after discounts, ensuring it doesn't go below 0
      return Math.max(baseTotal - totalDiscount, 0);
    },
    navigateBack() {
      window.history.back();
    },
    getParams() {
      const queryString = window.location.search;
      const params = new URLSearchParams(queryString);
      this.params = {
        method: params.get("method"),
        startDate: params.get("startDate"),
        endDate: params.get("endDate"),
        type: params.get("type"),
        staff: params.get("staff"),
      };
    },
    onRangeChange(filters) {
      this.getParams();
      this.params = { ...this.params, ...filters };
      this.loadOrders();
    },
    loadOrders() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        let limit = this.options.itemsPerPage ?? 10;
        let page = this.options.page || 1;
        if (limit === -1) limit = 1000000;
        let params = `?businessId=${bid}&page=${page}&limit=${limit}`;

        const param = this.params;
        if (param.method) params += `&paymentMethod=${param.method}`;
        if (param.startDate) params += `&dateStart=${param.startDate}`;
        if (param.endDate) params += `&dateEnd=${param.endDate}`;

        if (
          param.staff ||
          param.type == "service" ||
          param.type == "total deposit"
        ) {
          params += `&paymentStatus=completed&type=service`;
        }

        if (param.type == "total deposit") {
          params += `&hasDeposit=1`;
        }

        if (param.type == "product") {
          params += `&paymentStatus=completed&type=product`;
        }

        if (param.type == "service package") {
          params += `&paymentStatus=completed&type=servicePackage`;
        }

        if (param.type == "membership") {
          params += `&paymentStatus=completed&type=membership`;
        }

        if (param.type == "voucher") {
          params += `&paymentStatus=completed&type=voucher`;
        }

        this.isLoading = true;
        // const params = `?businessId=${
        //   (this.role.business as Business)._id
        // }&page=${this.options.page || 1}&limit=${
        //   this.options.itemsPerPage || 10
        // }`;
        this.fetchOrderList(params).finally(() => (this.isLoading = false));
        this.fetchClientList(`?businessId=${bid}`);
      }
    },
    row_classes(item: any) {
      return item.read == false ? "light-green lighten-5" : "";
    },
    getProductsLinks(products: any[]) {
      return products.map((item) => {
        const options = item.options;
        return { title: item.product.title, id: item.product._id, options };
      });
    },
    deleteOrder(orderId: string) {
      this.dropOrder(orderId);
    },
    selectOrderStatus(status: string) {
      this.filters.status = status;
    },
    remove(item: any) {
      //
    },
    exportCsv() {
      this.downloadCsv(
        this.filteredData.map((x) => {
          return {
            "Order ID": x.orderNo,
            Customer: x.client?.fullName,
            "Order Date": x.createdAt,
            "Payment Status": x.paymentStatus,
            "Paid Amount": x.payment?.amount,
            "Order Total": x.cost,
            Amount: this.calculateCategoryTotal(x),
          };
        }),
        "orders.csv"
      );
    },
    downloadCsv(data: Record<string, any>[], filename = "export.csv") {
      // Get headers from the first object's keys
      const headers = Object.keys(data[0]);

      // Create CSV rows
      const csvRows = [
        // Header row
        headers.join(","),
        // Data rows
        ...data.map((row) =>
          headers
            .map((header) => {
              const cell = row[header];
              // Handle cell content that needs quotes (contains commas or quotes)
              if (
                typeof cell === "string" &&
                (cell.includes(",") || cell.includes('"'))
              ) {
                return `"${cell.replace(/"/g, '""')}"`;
              }
              return cell?.toString() ?? "";
            })
            .join(",")
        ),
      ];

      // Combine rows with newlines
      const csvContent = csvRows.join("\n");

      // Create blob and download
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("ORDER_STORE")) {
      this.$store.registerModule("ORDER_STORE", OrderModule);
    }
    if (!this.$store.hasModule("ORDER_CLIENTS")) {
      this.$store.registerModule("ORDER_CLIENTS", ClientModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("ORDER_STORE");
    this.$store.unregisterModule("ORDER_CLIENTS");
  },
});
