

























import Vue from "vue";
import { api } from "@/util/axios";
import moment from "moment-timezone";
import ReportDateFilter from "./ReportDateFilterLean.vue";

const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";
export default Vue.extend<any, any, any, any>({
  name: "TopFilter",
  components: {
    ReportDateFilter,
  },

  props: {
    cardView: {
      default: false,
    },
  },
  data: () => ({
    startDate: null,
    endDate: null,
    applyDateFilter: true,
    applyFilter: [],
    filterStart: null,
    filterEnd: null,
    params: {},
    stats: 0,
    statsTitle: "",
  }),
  methods: {
    getParams() {
      const queryString = window.location.search;
      const params = new URLSearchParams(queryString);

      this.params = {
        method: params.get("method"),
        startDate: params.get("startDate"),
        endDate: params.get("endDate"),
        type: params.get("type"),
        staff: params.get("staff"),
      };
    },

    toggleFilter() {
      if (this.applyDateFilter) {
        this.applyDateFilter = true;
      } else {
        this.applyDateFilter = false;
      }
    },
    onRangeChange(filters) {
      this.filterStart = filters.start;
      this.filterEnd = filters.end;

      if (this.applyDateFilter) {
        this.startDate = filters.start;
        this.endDate = filters.end;
        this.fetchReports();
        this.$emit("dateChanged", {
          startDate: this.startDate,
          endDate: this.filterEnd,
        });
      }
    },
    fetchReports() {
      const reportType = this.params.type;
      if (reportType) {
        this.statsTitle = reportType
          .split("-")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        this.fetchClientStats(reportType);
      }
    },
    fetchClientStats(reportType: string) {
      const businessId = this.$store.getters.role.business._id;
      let apiUrl = `/v1/sales-ledger/client-report?businessId=${businessId}`;

      if (this.startDate) {
        const date = new Date(this.startDate);
        date.setHours(0, 0, 0, 0);
        apiUrl += `&startDate=${date.toISOString()}`;
      }

      if (this.endDate) {
        const date = new Date(this.endDate);
        date.setHours(23, 59, 59, 59);
        apiUrl += `&endDate=${date.toISOString()}`;
      }

      api.get(apiUrl).then((response) => {
        const report = response.data.reports.find((r) =>
          r.report_name.toLowerCase().includes(reportType)
        );
        if (report) {
          this.stats = report.value;
        } else {
          console.log(response.data.reports);
          // alert("Not found");
        }
      });
    },
    formatDate(date: string) {
      if (!date) return null;

      return moment(date).tz(tz).format("DD/MM/YYYY");
    },
    clear() {
      this.startDate = null;
      this.endDate = null;
    },
    setFilters(value) {
      this.applyDateFilters = value;
    },
    navigate(item) {
      const query: any = {
        tab: "orders",
        type: item.report_name.toLowerCase().slice(0, -1),
      };
      if (this.startDate) {
        const date = new Date(this.startDate);
        date.setHours(0, 0, 0, 0);
        query.startDate = date.toISOString();
      }
      if (this.endDate) {
        const date = new Date(this.endDate);
        date.setHours(23, 59, 59, 59);
        query.endDate = date.toISOString();
      }
      this.$router.push({
        path: "/products",
        query,
      });
    },
  },
  watch: {
    applyDateFilter(x) {
      if (!x) {
        this.starDate = null;
        this.endDate = null;
        this.$emit("dateChanged", {
          startDate: this.startDate,
          endDate: this.endDate,
        });
      } else {
        this.startDate = this.filterStart;
        this.endDate = this.filterEnd;
        this.$emit("dateChanged", {
          startDate: this.startDate,
          endDate: this.endDate,
        });
      }
    },
    startDate() {
      this.fetchReports();
    },
    endDate() {
      this.fetchReports();
    },
  },
  mounted() {
    this.getParams();
    this.fetchReports();
  },
});
