
























































import { EmployeePermission } from "@/types";
import Vue from "vue";
import ActionButtons from "@/components/ActionButtons.vue";
import SalesReportList from "@/components/reports/SalesReportList.vue";
import EmployeeReportList from "@/components/reports/EmployeeReportList.vue";
import MarketingReportList from "@/components/reports/MarketingReportList.vue";
import FinancialReportList from "@/components/reports/FinancialReportList.vue";
import PaymentReportList from "@/components/reports/PaymentReportList.vue";
import ClientReportList from "@/components/reports/ClientReportList.vue";
import LocationReportList from "@/components/reports/LocationReportList.vue";
import permission from "@/store/modules/permission";
import NotFound from "@/views/NotPermitted.vue";

export default Vue.extend<any, any, any, any>({
  components: {
    ActionButtons,
    SalesReportList,
    EmployeeReportList,
    MarketingReportList,
    FinancialReportList,
    PaymentReportList,
    ClientReportList,
    // LocationReportList,
    NotFound,
  },
  name: "Reports",
  computed: {
    permission(): EmployeePermission {
      return this.$store.getters.permission;
    },
    hasPermission() {
      return (
        this.$store.getters.permission &&
        ["Default For Owner", "OWNER", "Manager", "Staff"].includes(
          this.$store.getters.permission.permissionGroup.name
        )
      );
    },
  },
  data: () => ({
    activeTab: 0,
    items: [
      { icon: "", title: "Sales ", tab: "sales" },
      { icon: "", title: "Staff", tab: "staff" },
      { icon: "", title: "Marketing ", tab: "marketing" },
      { icon: "", title: "Finance", tab: "finance" },
      { icon: "", title: "Payment", tab: "payment" },
      { icon: "", title: "Client ", tab: "client" },
      // { icon: "", title: "Location", tab: "location" },
    ],
  }),
  created() {
    const tabParam = this.$route.query.tab;
    if (tabParam) {
      const idx = this.items.findIndex((item) => item.tab === tabParam);
      this.activeTab = idx !== -1 ? idx : 0;
    }
  },
  watch: {
    activeTab(newVal: number) {
      const tabValue = this.items[newVal].tab;
      this.$router.replace({ query: { ...this.$route.query, tab: tabValue } });
    },
  },
});
